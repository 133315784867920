<template>
    <div>
        <div>
        <h2> Patient Forms </h2>
        <SelectButton id="toggle" v-model="value" :options="options" />
        </div>
        <!-- Card View -->
        <div class="grid" v-if="value === 'Grid'">
            <Card v-for="form in forms" :key="form.name" class="card">
                <template #header>
                    <img :alt="form.name + ' thumbnail'" 
                    :src="require('@/assets/patient-forms/thumbnails/' + form.img_src)">
                </template>
                <template #title>
                    <div class="custom-title">
                        {{form.name}}
                    </div>
                </template>
                <template #content>
                    <div class="custom-content">
                        {{form.description}}
                    </div>
                </template>
                <template #footer>
                    <a :href="'/forms/' + form.download_src" target="_blank">
                        <Button icon="pi pi-download" label="Download" class="p-button-custom"/>
                    </a>
                </template>
            </Card>
        </div>
        <!-- List View -->
        <div class="list" v-if="value === 'List'">
            <ul id="formList">
                <li v-for="form in forms" :key="form.name">
                    <a :href="'/forms/' + form.download_src" target="_blank">
                        {{form.name}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import SelectButton from 'primevue/selectbutton';

export default {
    // Form file are located in the public folder
    // Form thumbnails are 205x265px
    name: "Patient Forms",
    components: {
        Button,
        SelectButton,
        Card
    },
    data() {
        return {
            value: 'Grid',
            options: ['Grid', 'List'],
            forms: [
                {   name: 'Authorization to Request Health Information Form',
                    img_src: 'EVFM_Incoming_Authorization_to_Request_Health_Information.png',
                    download_src: 'EVFM_Incoming_Authorization_to_Request_Health_Information.pdf',
                    description: `Authorizes Elkhorn Valley Family Medicine to request medical 
                    records from other proviers.`
                },
                {   name: 'Authorization to Disclose Health Information Form',
                    img_src: 'EVFM_Authorization_to_Disclose_Health_Information_Form.png',
                    download_src: 'EVFM_Authorization_to_Disclose_Health_Information_Form.pdf',
                    description: `Gives Elkhorn Valley Family Medicine the authorization to release 
                    your medical records to another provider.`
                },
                {   name: 'Consent of Medical Treatment Form',
                    img_src: 'EVFM_Consent_of_Medical_Treatment.png',
                    download_src: 'EVFM_Consent_of_Medical_Treatment.pdf',
                    description: `Consent to disclose health information for treatment, payment 
                    or health care operations.`
                },
                {   name: 'Financial Policies',
                    img_src: 'EVFM_Financial_Policies.png',
                    download_src: 'EVFM_Financial_Policies.pdf',
                    description: `Our financial and patient payment policy.`
                },
                {   name: 'HIPAA Form',
                    img_src: 'EVFM_HIPAA_2024.png',
                    download_src: 'EVFM_HIPAA_2024.pdf',
                    description: `Authorizes Elkhorn Valley Family Medicine to use 
                    or disclose your protected health information (PHI).`
                },
                {   name: 'Medicare Form',
                    img_src: 'EVFM_Medicare_Form.png',
                    download_src: 'EVFM_Medicare_Form.pdf',
                    description: `Form required to bill Medicare for treatment.`
                },
                {   name: 'Minor Consent Form',
                    img_src: 'EVFM_Minor_Consent_Form.png',
                    download_src: 'EVFM_Minor_Consent_Form.pdf',
                    description: `Allows for treatment of minors without an accompanying parent.`
                },
                {   name: 'New Patient Form',
                    img_src: 'EVFM_New_Patient_Form.png',
                    download_src: 'EVFM_New_Patient_Form.pdf',
                    description: `A form required by all new patients. Current patients may also
                    be asked to fill out a new form any time we update it.`
                },
                {   name: 'Release of Health Information to Family Members Form',
                    img_src: 'EVFM_PHI_Family_Release_Form.png',
                    download_src: 'EVFM_PHI_Family_Release_Form.pdf',
                    description: `Gives EVFM authorization to discuss your health and
                         treatment with anyone you list on the form.`
                }
            ]
        }
    },
    methods: {
        getImageURL: function(picture) {
            return '@/assets/patient-forms/thumbnails/' + picture; 
        }
    }
}
</script>

<style scoped>
    a:link {
        color: #164522;
    }
    a:visited {
        color: #320e14;
    }
    .card {
        width: 20rem;
        margin-bottom: 2em;
        display: inline-block;
        margin: 10px 10px 25px 10px;
        vertical-align: top;
        height: 465px;
    }
    .card img {
        height: 170px;
        width: 130px;
    }
    .cardTitle {
        font-size: 18px;
    }
    #formList {
        list-style-type: none;
    }
    h2 {
        display: inline-block;
        padding-left: 16%;
    }
    #toggle {
        float: right;
        padding: 15px 5% 0 0;
    }
    .p-button-custom {
        background-color: #7F2532;
        border-color: #7F2532;
    }
    .p-button-custom:hover {
        background-color: #4c161e;
        border-color: #4c161e;
    }
    .custom-content {
        height: 75px;
    }
    .custom-title {
        height:96px;
    }

</style>